import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, PermissionBasedGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/booking'));
// contact
const ContactListPage = lazy(() => import('src/pages/dashboard/contact/list'));
const ContactCreatePage = lazy(() => import('src/pages/dashboard/contact/new'));
const ContactEditPage = lazy(() => import('src/pages/dashboard/contact/edit'));
const ContactSubLedgerPage = lazy(() => import('src/pages/dashboard/contact/subledger'));
const ContactAnalyticsPage = lazy(() => import('src/pages/dashboard/contact/analytics'));
const OrganizerSubLedgerPage = lazy(() => import('src/pages/dashboard/contact/organizer-subledger'));
const DistributorSubLedgerPage = lazy(() => import('src/pages/dashboard/contact/distributor-subledger'));

// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
const ProductAnalyticsPage = lazy(() => import('src/pages/dashboard/product/analytics'));

// PURCHASE
const PurchaseListPage = lazy(() => import('src/pages/dashboard/purchase/list'));
const PurchaseCreatePage = lazy(() => import('src/pages/dashboard/purchase/new'));
const PurchaseEditPage = lazy(() => import('src/pages/dashboard/purchase/edit'));
const PurchaseDetailsPage = lazy(() => import('src/pages/dashboard/purchase/details'));
const PurchaseAnalyticsPage = lazy(() => import('src/pages/dashboard/purchase/analytics'));

// Sales
const SalesListPage = lazy(() => import('src/pages/dashboard/sales/list'));
const SalesCreatePage = lazy(() => import('src/pages/dashboard/sales/new'));
const SalesEditPage = lazy(() => import('src/pages/dashboard/sales/edit'));
const SalesDetailsPage = lazy(() => import('src/pages/dashboard/sales/details'));
const SalesAnalyticsPage = lazy(() => import('src/pages/dashboard/sales/analytics'));

// Sales-returns
const ReturnsListPage = lazy(() => import('src/pages/dashboard/sales-returns/list'));
const ReturnsCreatePage = lazy(() => import('src/pages/dashboard/sales-returns/new'));
const ReturnsEditPage = lazy(() => import('src/pages/dashboard/sales-returns/edit'));
const ReturnsDetailsPage = lazy(() => import('src/pages/dashboard/sales-returns/details'));
const ReturnsAnalyticsPage = lazy(() => import('src/pages/dashboard/sales-returns/analytics'));

// PAYMENTS
const PaymentsListPage = lazy(() => import('src/pages/dashboard/payments/list'));
const PaymentsCreatePage = lazy(() => import('src/pages/dashboard/payments/new'));
const PaymentsEditPage = lazy(() => import('src/pages/dashboard/payments/edit'));
const PaymentsAnalysisPage = lazy(() => import('src/pages/dashboard/payments/analysis'));

// GODOWNS
const GodownsListPage = lazy(() => import('src/pages/dashboard/godowns/list'));
const GodownsCreatePage = lazy(() => import('src/pages/dashboard/godowns/new'));
const GodownsEditPage = lazy(() => import('src/pages/dashboard/godowns/edit'));
const GodownAnalyticsPage = lazy(()=>import('src/pages/dashboard/godowns/analytics'));

// BRANCH
const BranchListPage = lazy(() => import('src/pages/dashboard/branches/list'));
const BranchCreatePage = lazy(() => import('src/pages/dashboard/branches/new'));
const BranchEditPage = lazy(() => import('src/pages/dashboard/branches/edit'));

// STAFF
const StaffListPage = lazy(() => import('src/pages/dashboard/staff/list'));
const StaffCreatePage = lazy(() => import('src/pages/dashboard/staff/new'));
const StaffEditPage = lazy(() => import('src/pages/dashboard/staff/edit'));

// USER
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));

// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// WELCOME PAGE

const WelcomePage= lazy(()=>import('src/pages/dashboard/welcome'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        element:
          <PermissionBasedGuard hasContent permissions={['is_read_branch_dashboard']}>
            <IndexPage />
          </PermissionBasedGuard>, index: true
      },
      {
        path: 'user',
        children: [
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          {
            element: (
              <PermissionBasedGuard hasContent permissions={['is_read_products']}>
                <ProductListPage />
              </PermissionBasedGuard>
            ),
            index: true,
          },
          {
            path: ':id',
            element: (
              <PermissionBasedGuard hasContent permissions={['is_read_products']}>
                <ProductDetailsPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'new',
            element: (
              <PermissionBasedGuard hasContent permissions={['is_write_products']}>
                <ProductCreatePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/edit',
            element: (
              <PermissionBasedGuard hasContent permissions={['is_update_products']}>
                <ProductEditPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'analytics',
            element: (
              <PermissionBasedGuard hasContent permissions={['is_read_products_analytics']}>
                <ProductAnalyticsPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'contact',
        children: [
          {
            element: (
              <PermissionBasedGuard hasContent permissions={['is_read_contacts']}>
                <ContactListPage />
              </PermissionBasedGuard>
            ), index: true
          },
          {
            path: 'new', element: (
              <PermissionBasedGuard hasContent permissions={['is_write_contacts']}>
                <ContactCreatePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/edit', element: (
              <PermissionBasedGuard hasContent permissions={['is_update_contacts']}>
                <ContactEditPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/subledger', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_contacts']}>
                <ContactSubLedgerPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/organizer-subledger', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_contacts']}>
                <OrganizerSubLedgerPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/distributor-subledger', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_contacts']}>
                <DistributorSubLedgerPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'analytics', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_contacts_analytics']}>
                <ContactAnalyticsPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'purchase',
        children: [
          {
            element: (
              <PermissionBasedGuard hasContent permissions={['is_read_purchases']}>
                <PurchaseListPage />
              </PermissionBasedGuard>
            ), index: true
          },
          {
            path: ':id', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_purchases']}>
                <PurchaseDetailsPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'new', element: (
              <PermissionBasedGuard hasContent permissions={['is_write_purchases']}>
                <PurchaseCreatePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/edit', element: (
              <PermissionBasedGuard hasContent permissions={['is_update_purchases']}>
                <PurchaseEditPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'analytics', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_purchases_analytics']}>
                <PurchaseAnalyticsPage />
              </PermissionBasedGuard>
            )
          },
        ],
      },
      {
        path: 'sales',
        children: [
          {
            element: (
              <PermissionBasedGuard hasContent permissions={['is_read_sales']}>
                <SalesListPage />
              </PermissionBasedGuard>
            ), index: true
          },
          {
            path: ':id', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_sales']}>
                <SalesDetailsPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'new', element: (
              <PermissionBasedGuard hasContent permissions={['is_write_sales']}>
                <SalesCreatePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/edit', element: (
              <PermissionBasedGuard hasContent permissions={['is_update_sales']}>
                <SalesEditPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'analytics', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_sales_analytics']}>
                <SalesAnalyticsPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'returns',
        children: [
          {
            element: (
              <PermissionBasedGuard hasContent permissions={['is_read_sales_returns']}>
                <ReturnsListPage />
              </PermissionBasedGuard>
            ), index: true
          },
          {
            path: ':id', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_sales_returns']}>
                <ReturnsDetailsPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'new', element: (
              <PermissionBasedGuard hasContent permissions={['is_write_sales_returns']}>
                <ReturnsCreatePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/edit', element: (
              <PermissionBasedGuard hasContent permissions={['is_update_sales_returns']}>
                <ReturnsEditPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'analytics', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_sales_returns_analytics']}>
                <ReturnsAnalyticsPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'payments',
        children: [
          {
            element: (
              <PermissionBasedGuard hasContent permissions={['is_read_payments']}>
                <PaymentsListPage />
              </PermissionBasedGuard>
            ), index: true
          },
          {
            path: 'new', element: (
              <PermissionBasedGuard hasContent permissions={['is_write_payments']}>
                <PaymentsCreatePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/edit', element: (
              <PermissionBasedGuard hasContent permissions={['is_update_payments']}>
                <PaymentsEditPage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: 'analytics', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_payments_analytics']}>
                <PaymentsAnalysisPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'godowns',
        children: [
          { element: (
            <PermissionBasedGuard hasContent permissions={['is_read_godowns']}>
              <GodownsListPage />
            </PermissionBasedGuard>
          ), index: true },
          { path: 'new', element: (
            <PermissionBasedGuard hasContent permissions={['is_write_godowns']}>
              <GodownsCreatePage />
            </PermissionBasedGuard>
          ), },
          { path: ':id/edit', element: (
            <PermissionBasedGuard hasContent permissions={['is_update_godowns']}>
              <GodownsEditPage />
            </PermissionBasedGuard>
          ), },
          {
            path: 'analytics', element: (
              <PermissionBasedGuard hasContent permissions={['is_read_godowns']}>
                <GodownAnalyticsPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'branches',
        children: [
          {
            element: (
              <PermissionBasedGuard hasContent permissions={['is_clientadmin']}>
                <BranchListPage />
              </PermissionBasedGuard>
            ), index: true
          },
          {
            path: 'new', element: (
              <PermissionBasedGuard hasContent permissions={['is_clientadmin']}>
                <BranchCreatePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/edit', element: (
              <PermissionBasedGuard hasContent permissions={['is_clientadmin']}>
                <BranchEditPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {
        path: 'staff',
        children: [
          {
            element: (
              <PermissionBasedGuard hasContent permissions={['is_branchadmin']}>
                <StaffListPage />
              </PermissionBasedGuard>
            ), index: true
          },
          {
            path: 'new', element: (
              <PermissionBasedGuard hasContent permissions={['is_branchadmin']}>
                <StaffCreatePage />
              </PermissionBasedGuard>
            ),
          },
          {
            path: ':id/edit', element: (
              <PermissionBasedGuard hasContent permissions={['is_branchadmin']}>
                <StaffEditPage />
              </PermissionBasedGuard>
            ),
          },
        ],
      },
      {path: 'welcome',element:<WelcomePage/>},
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
